import React from "react"
import { graphql } from "gatsby"
import widont from "widont"

import Image from "../../../components/image"
import Layout, { athenaTheme } from "../../../components/layout"
import MarqueeText from "../../../components/marqueeText.js"
import ScrollProgressBar from "../../../components/scrollProgressBar"
import Contributors from "../../../components/athena/contributors.js"
import AthenaCta from "../../../components/athena/athenaCta.js"
import Typed from "../../../components/athena/typed.js"
import MoreArticles from "../../../components/athena/moreArticles.js"
import PreFooter from "../../../components/athena/preFooter"
import styles from "./dscout.module.scss"

let theme = {
  ...athenaTheme,
  background: styles.background,
  foreground: styles.foreground,
}

export default ({ data }) => {
  const article = data.craft.article
  const seo = article.seomatic
  const athenaCta = article.athenaCta
  // Assets
  // const scribble = data.craft.scribble
  // const border = data.craft.border
  const titleDoodad = data.craft.titleDoodad
  const doodads = data.craft.doodads
  const chart1 = data.craft.chart1
  const chart2 = data.craft.chart2

  return (
    <Layout seo={seo} theme={theme} mode="athena">
      <article>
        <ScrollProgressBar />
        <div className={styles.article}>
          <div className={styles.articleBody}>
            <div className={styles.scene}>
              <div className="wrapper">
                <div className="columns is-desktop is-centered">
                  <div className="column is-10-desktop">
                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={titleDoodad.url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>
                    <h1 className={styles.title}>{widont(article.title)}</h1>
                    <div className={`${styles.cast} ${styles.mb}`}>
                      <h2 className="underline">Characters:</h2>
                      <ul>
                        <li>Gen Z</li>
                        <li>dscout</li>
                        <li>Zeus Jones</li>
                      </ul>
                    </div>
                    <div className={styles.mb}>
                      <p className="italic">
                        {widont(`Together with dscout, we conducted a study in January
                        2020 to better understand Gen Z identity formation,
                        expression and fluidity. We heard from 42 Gen Z
                        participants who told us about themselves, their
                        backgrounds and the role identity plays in their lives.`)}
                      </p>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <h2 className="fw-bold uppercase">
                          Scene 1: The Curated Self
                        </h2>
                        <p className="italic">
                          {widont(`A generation declares freedom. It feels a pull to
                          remake the world — starting with themselves. Where
                          previous generations were proud to wear labels, the
                          youngest wants to create their own.`)}
                        </p>
                        <p className="italic">Enter Gen Z</p>
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Kevin, 19:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;Sometimes I feel compelled to align with commonly or familial accepted norms (e.g. my current gender is male because I am biologically male) for the sake of societal acceptance and conformity. But I’ve been working on liberating myself from societal constraints when it comes to my personal brand.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(`ZEUS JONES: They prefer exploration over mastery.
                          Revision over definition. And fluidity over fixity. In
                          sexuality, gender and personal brand, Gen Z is the
                          most fluid generation in history.`)}
                        </p>
                      </div>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[0].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* .column */}
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .scene 1 */}

            <div className={styles.scene}>
              <div className="wrapper">
                <div className="columns is-desktop is-centered">
                  <div className="column is-10-desktop">
                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <h2 className="fw-bold uppercase">
                          Scene 2: Identity Formation
                        </h2>
                        <p className="italic">
                          {widont(`Audiences have been getting more fragmented for years. But Gen Z is working with a much bigger set of much
                          smaller pieces when they construct the audiences they belong to.`)}
                        </p>
                        <p className="italic">Enter Gen Z</p>
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Adam, 22:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I feel like I identify with the choices I make as much as I identify with my core qualities that I was born with/where I am from. Sometimes I feel as though I value them even more because my choices are an expression of how I see myself and what I want to be.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(`ZEUS JONES: They understand all the components of
                          their self, and how these pieces intersect. These
                          intersections produce powerfully complex, creative,
                          engaged people.`)}
                        </p>
                      </div>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[1].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Xavier, 22:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I think experiences really craft a person. And those experiences normally occur due to the all encompassing intersections of identity (Race, Gender, Sexuality, Class, Disability, Religion, Age). I could’ve been in this same body and had vastly different experiences and I think I would have grown into someone pretty different despite the same hypothetical genetic makeup.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(`ZEUS JONES: Greater awareness of these pieces leads to
                          rich personal meaning. Identity today connects the
                          threads into a more detailed tapestry.`)}
                        </p>
                      </div>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[2].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Dray, 22:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I feel that my Black Southern heritage is extremely important to my identity as a whole. I feel most affinity with Black trans and queer people from the American South who understand the specific terrain of the South, and the violence related to being queer and of-color that makes our identities feel more pronounced. I also think there’s a specific legacy of QTPOC from the South who don’t see the South as regressive and backwards, and instead as a site of incredible resistance and way-making that is unique to the majority presence of Black bodies here.&rdquo;" />
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* .column */}
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .scene 2 */}

            <div className={styles.scene}>
              <div className={styles.mb}>
                <div className="wrapper">
                  <div className="columns is-desktop is-centered">
                    <div className="column is-10-desktop">
                      <div className={styles.maxwidth}>
                        <div className="user-content-athena-no-headings">
                          <p className="mono">
                            {widont(`ZEUS JONES: Our study found that some components of
                          identity are bigger drivers in defining personal
                          identity than others.`)}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* .column */}
                  </div>
                  {/* .columns */}
                </div>
                {/* .wrapper */}
              </div>
              {/* .mb */}

              <div className={styles.listWrapper}>
                <div className={styles.list}>
                  <ol>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>Experiences</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText dir={-1}>
                        <span className={styles.listItem}>Hobbies</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>
                          Likes + Dislikes
                        </span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText dir={-1}>
                        <span className={styles.listItem}>Social Circle</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>Gender</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText dir={-1}>
                        <span className={styles.listItem}>Heritage</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>Education</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText dir={-1}>
                        <span className={styles.listItem}>Sexuality</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>
                          Where I&rsquo;m From
                        </span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText dir={-1}>
                        <span className={styles.listItem}>Job</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>
                          Who I&rsquo;m Associated With
                        </span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText dir={-1}>
                        <span className={styles.listItem}>Where I Live</span>
                      </MarqueeText>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* .scene */}

            <div className={styles.scene}>
              <div className="wrapper">
                <div className="columns is-desktop is-centered">
                  <div className="column is-10-desktop">
                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <h2 className="fw-bold uppercase">
                          Scene 3: Fluid Identities
                        </h2>
                        <p className="italic">
                          {widont(
                            `They accept fewer limitations. They ask, “Why can’t I?” The boundaries of identity are more in play than ever, especially in sexuality, gender and gender expression.`
                          )}
                        </p>
                        <p className="italic">Enter Gen Z</p>
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Kiira, 20:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;Sexualities and preferences can change, and I think it's important to accept these changes without needing to stick ourselves in a box. My sexual identity has shifted several times, and each time it reflects new experiences, relationships and circumstances.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(
                            `ZEUS JONES: They see greater depth and shading in each other. They say yes to more facets, and accept when those facets are present in others.`
                          )}
                        </p>
                      </div>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[3].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Murray, 23:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I’ve never really given my assigned-at-birth gender much thought, I’ve been fine with being a bloke in most regards. However I’m happy to and often reject masculine things and when they come up (football, lad culture, normies etc). This to me is fluidity, being at peace with myself and others’ actions and actively pruning my world around me to maintain that peace.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(
                            `ZEUS JONES: And what feels true now is always open for revision later. Identity is as much a process as an outcome.`
                          )}
                        </p>
                      </div>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[4].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Alexis, 19:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;My sexuality has never been fully stable, as I'm always learning more and more each day. I used to think I was straight, but over time, things changed and I realized that I was actually queer! And even learning about my asexuality was another step in my identity!&rdquo;" />
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* .column */}
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .scene 3 */}

            <div className={styles.scene}>
              <div className="wrapper">
                <div className="columns is-desktop is-centered">
                  <div className="column is-10-desktop">
                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(
                            `ZEUS JONES: How fluid are different aspects of identity for each generation compared to the average adult?`
                          )}
                        </p>
                      </div>
                    </div>
                    <Image
                      width={chart1.width}
                      height={chart1.height}
                      // sizes="(min-width: 1424px) 306px, (min-width: 1024px) calc((100vw - 5rem)/4), (min-width: 768px) calc((100vw - 5rem)/3), 180px"
                      url={chart1.url}
                      alt={chart1.altText}
                      // Setting the role since the hidden table below shares this data
                      htmlAttributes={{
                        role: "presentation",
                      }}
                    />

                    <table className="screen-reader-only">
                      <caption>
                        How fluid are different aspects of identity for each
                        generation compared to the average adult?
                      </caption>
                      <tbody>
                        <tr>
                          <th scope="col">Generation</th>
                          <th scope="col">Gender</th>
                          <th scope="col">Sexuality</th>
                          <th scope="col">Personal Brand</th>
                        </tr>
                        <tr>
                          <th scope="row">Gen X</th>
                          <td align="center">-4%</td>
                          <td align="center">-13%</td>
                          <td align="center">-11%</td>
                        </tr>
                        <tr>
                          <th scope="row">Millenials</th>
                          <td align="center">0%</td>
                          <td align="center">4%</td>
                          <td align="center">4%</td>
                        </tr>
                        <tr>
                          <th scope="row">Gen Z</th>
                          <td align="center">4%</td>
                          <td align="center">10%</td>
                          <td align="center">6%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* .column */}
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .scene */}

            <div className={styles.scene}>
              <div className="wrapper">
                <div className="columns is-desktop is-centered">
                  <div className="column is-10-desktop">
                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <h2 className="fw-bold uppercase">
                          Scene 4: External Identity Performance
                        </h2>
                        <p className="italic">
                          {widont(
                            `While sea change happens inside, the surface constantly morphs.`
                          )}
                        </p>
                        <p className="italic">Enter Gen Z</p>
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>
                          Michaelina, 19:
                        </span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I think being able to experiment with your outer appearance is important for growth and learning even more about what you like that strengthens your identity further. However I probably wouldn’t change my language or vocal tone as that’s more ingrained in me.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(
                            `ZEUS JONES: The simplest things to change — hair, clothing and presentation — are fully explored. Even social codes, language and vocal delivery are up for examination.`
                          )}
                        </p>
                      </div>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[5].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Kevin, 19:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;Vocal tone seems to have an effect on how people see me, but it’s a lot of work to change it. There are a lot of different types of clothes and hair styles and they are easy to change. Clothes and hair are just as effective in expressing your personal brand as changing your vocal tone—they’re just easier to change.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[6].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Dray, 22:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I play with physical aesthetics all the time; changes to my clothes and makeup often reflect how I’m feeling or changes to my life that I’m making. I also see my body as a canvas, so adding makeup or permanent tattoos feels like creating meaningful art on my body.&rdquo;" />
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* .column */}
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .scene 4 */}

            <div className={styles.scene}>
              <div className={styles.mb}>
                <div className="wrapper">
                  <div className="columns is-desktop is-centered">
                    <div className="column is-10-desktop">
                      <div className={`${styles.maxwidth} ${styles.mb}`}>
                        <div className="user-content-athena-no-headings">
                          <p className="mono">
                            {widont(
                              `ZEUS JONES: Which of the following aspects of your identity, if any, are you most likely to change or experiment with?`
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* .column */}
                  </div>
                  {/* .columns */}
                </div>
                {/* .wrapper */}
              </div>
              {/* .mb */}

              <div className={`${styles.listWrapper} ${styles.listWrapper2}`}>
                <div className={styles.list}>
                  <ol>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>What I wear</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>My hairstyle</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>Language i use</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>
                          Body (ex. tattoos, piercings)
                        </span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>
                          How I talk about myself
                        </span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>Vocal tone</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>Make-up</span>
                      </MarqueeText>
                    </li>
                    <li>
                      <MarqueeText>
                        <span className={styles.listItem}>
                          Physical mannerisms
                        </span>
                      </MarqueeText>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            {/* .scene */}

            <div className={styles.scene}>
              <div className="wrapper">
                <div className="columns is-desktop is-centered">
                  <div className="column is-10-desktop">
                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <h2 className="fw-bold uppercase">
                          Scene 5: Online Identities
                        </h2>
                        <p className="italic">
                          {widont(
                            `And social media is still a place for safe, simple experimentation.`
                          )}
                        </p>
                        <p className="italic">Enter Gen Z</p>
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Kade, 23:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I am the most confident I can be online and portray a very strong persona when in real life I’m not always 100% able to in my current environment.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(
                            `ZEUS JONES: Though we often think of social as a vitriolic place, it’s also a way to form communities and explore identity aspects that aren’t ready for IRL expression.`
                          )}
                        </p>
                      </div>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[7].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Julio, 23:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I have multiple Twitter accounts. One for my professional persona, my social persona, and sexual persona. Each fulfill a different purpose depending on what I’m trying to communicate.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[8].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Kevin, 19:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;I’m reluctant to wear a t-shirt that says I’m asexual on it, because there is always a chance that one person will hate me just for that. It’s safer when you have a computer screen to protect you physically.&rdquo;" />
                        </span>
                      </p>
                    </div>

                    <div className={styles.mb}>
                      <div className={styles.doodad}>
                        <Image
                          url={doodads[9].url}
                          htmlAttributes={{
                            role: "presentation",
                          }}
                        />
                      </div>
                    </div>

                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(
                            `ZEUS JONES: But negotiating identity is intense, taxing and sometimes negative. The expectation that social media is a happy place creates dissonance — and a bottling of emotions.`
                          )}
                        </p>
                      </div>
                    </div>

                    <div className={`${styles.quote} ${styles.mb}`}>
                      <p>
                        <span className={styles.quoteName}>Lena, 19:</span>
                        <span className={styles.quoteQuote}>
                          <Typed string="&ldquo;Well obviously everything presented online is the happiest, most positive portrayal of the happy moments of our lives. Whereas in reality, my identity includes sad experiences that are not posted online.&rdquo;" />
                        </span>
                      </p>
                    </div>
                  </div>
                  {/* .column */}
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .scene 4 */}

            <div className={styles.scene}>
              <div className="wrapper">
                <div className="columns is-desktop is-centered">
                  <div className="column is-10-desktop">
                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="mono">
                          {widont(
                            `Zeus Jones:  Are there differences in the way that you present yourself and your identity online compared to in real life? `
                          )}
                        </p>
                      </div>
                    </div>
                    <Image
                      width={chart2.width}
                      height={chart2.height}
                      // sizes="(min-width: 1424px) 306px, (min-width: 1024px) calc((100vw - 5rem)/4), (min-width: 768px) calc((100vw - 5rem)/3), 180px"
                      url={chart2.url}
                      alt={chart2.altText}
                      // Setting the role since the hidden table below shares this data
                      htmlAttributes={{
                        role: "presentation",
                      }}
                    />

                    <table className="screen-reader-only">
                      <caption>
                        Are there differences in the way that you present
                        yourself and your identity online compared to in real
                        life?
                      </caption>
                      <tbody>
                        <tr>
                          <th scope="col">Response</th>
                          <th scope="col">Yes</th>
                          <th scope="col">No</th>
                        </tr>
                        <tr>
                          <th scope="row">Results</th>
                          <td align="center">55%</td>
                          <td align="center">45%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {/* .column */}
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .scene */}

            <div className={styles.scene}>
              <div className="wrapper">
                <div className="columns is-desktop is-centered">
                  <div className="column is-10-desktop">
                    <div className={`${styles.maxwidth} ${styles.mb}`}>
                      <div className="user-content-athena-no-headings">
                        <p className="italic">
                          {widont(
                            `Our understanding of Gen Z identity is just beginning to come together — and its fluidity means that it might never fully fix into place.`
                          )}
                        </p>
                        <p className="italic">
                          {widont(
                            `Big questions loom: How will it continue to evolve as younger generations grow up online? How will they showcase more elements of identity performance, self-reflection and fluidity? And how will their overall awareness of identity deepen and mature?`
                          )}
                        </p>
                        <p className="italic">
                          Dig into the data on dscout’s People Nerds post:{" "}
                          <a
                            className="link"
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://dscout.com/people-nerds/gen-z-identity"
                          >
                            Gen Z, Identity, and Brand: How the “Digital Native”
                            Generation is Designing Itself
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* .column */}
                </div>
                {/* .columns */}
              </div>
              {/* .wrapper */}
            </div>
            {/* .scene */}

            <Contributors
              zeus={article.zeusContributors}
              other={article.otherContributors}
            />
            <AthenaCta data={athenaCta} />
            <MoreArticles id={article.id} issue={article.issue[0].id} />
          </div>
          {/* .article-body */}
        </div>
        {/* .article */}
      </article>
      <PreFooter />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    craft {
      article: entry(slug: [$slug], section: "athenaArticles") {
        title
        id
        seomatic {
          metaTagContainer
          metaLinkContainer
          metaTitleContainer
          metaJsonLdContainer
        }
        ... on Craft_athenaArticles_athenaArticleCustom_Entry {
          articleSubTitle
          issue {
            id
            title
          }
          zeusContributors {
            id
            title
            uri
          }
          otherContributors {
            ... on Craft_otherContributors_TableRow {
              name
              website
            }
          }
          athenaCta {
            ...AthenaCta
          }
        }
      }
      scribble: asset(id: 4711) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      border: asset(id: 4712) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      titleDoodad: asset(id: 4760, orderBy: "id") {
        url
        id
      }
      doodads: assets(
        id: [4713, 4714, 4715, 4716, 4717, 4718, 4719, 4720, 4721, 4722]
        orderBy: "id"
      ) {
        url
        id
      }
      chart1: asset(id: 4723) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
      chart2: asset(id: 4724) {
        url
        width
        height
        ... on Craft_athenaContent_Asset {
          altText
        }
      }
    }
  }
`

import React, { useRef, useEffect } from "react"
import ReactTyped from "react-typed"

const Typed = props => {
  const typeSpeed = props.typeSpeed ? props.typeSpeed : 32
  const typedParentRef = useRef(null)
  let typedInstance = useRef(null)

  useEffect(() => {
    // No typing yet!
    typedInstance.current.stop()

    const observer = new IntersectionObserver(function callback(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // Start it if it's in the viewport
          typedInstance.current.start()
        } else {
          // Stop it if it's out of the viewport to prevent the page from
          // jumping around
          typedInstance.current.stop()
        }
      })
    })

    // Observe the visibility of the parent element to trigger start/stop
    observer.observe(typedParentRef.current)

    // Teardown the observer and instance of Typed
    return () => {
      typedInstance.current.destroy()
      observer.disconnect()
    }
  })

  return (
    <span ref={typedParentRef}>
      {/* Keep a visible copy of the text on the page for SEO & a11y reasons */}
      <span className="screen-reader-only">{props.string}</span>

      {/* Typed text is hidden to screen-readers */}
      <span aria-hidden>
        <ReactTyped
          typedRef={typed => {
            typedInstance.current = typed
          }}
          strings={[props.string]}
          typeSpeed={typeSpeed}
        />
      </span>
    </span>
  )
}

export default Typed
